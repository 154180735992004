import React from 'react';

const ContentAccuracy = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.0629 27.4335C23.3945 27.4385 22.7394 27.2454 22.1811 26.8785C21.6229 26.5115 21.1867 25.9873 20.9274 25.3725C20.6681 24.7577 20.5975 24.08 20.7248 23.4252C20.8522 22.7703 21.1713 22.1679 21.6422 21.6945C22.1131 21.221 22.7145 20.8978 23.3698 20.7658C24.0251 20.6338 24.7048 20.699 25.3228 20.9531C25.9409 21.2072 26.4698 21.6387 26.8417 22.193C27.2136 22.7473 27.4122 23.3994 27.4122 24.0664C27.4122 24.9551 27.0602 25.8076 26.4329 26.4384C25.8055 27.0691 24.9535 27.4268 24.0629 27.4335ZM24.0629 21.9649C23.6454 21.9599 23.2356 22.0789 22.886 22.3067C22.5364 22.5345 22.2626 22.8609 22.0993 23.2444C21.9361 23.6279 21.8909 24.0512 21.9693 24.4605C22.0476 24.8697 22.2463 25.2467 22.5398 25.5431C22.8332 25.8395 23.2084 26.0422 23.6176 26.1253C24.0267 26.2085 24.4509 26.1683 24.8372 26.01C25.2235 25.8518 25.5543 25.5826 25.7868 25.2365C26.0193 24.8904 26.1436 24.4831 26.1436 24.0664C26.1436 23.5135 25.9254 22.9828 25.5359 22.5895C25.1465 22.1961 24.617 21.9716 24.0629 21.9649Z"
      fill="#FCFCFD"
    />
    <path
      d="M24.0629 27.4335C23.3945 27.4385 22.7394 27.2454 22.1811 26.8785C21.6229 26.5115 21.1867 25.9873 20.9274 25.3725C20.6681 24.7577 20.5975 24.08 20.7248 23.4252C20.8522 22.7703 21.1713 22.1679 21.6422 21.6945C22.1131 21.221 22.7145 20.8978 23.3698 20.7658C24.0251 20.6338 24.7048 20.699 25.3228 20.9531C25.9409 21.2072 26.4698 21.6387 26.8417 22.193C27.2136 22.7473 27.4122 23.3994 27.4122 24.0664C27.4122 24.9551 27.0602 25.8076 26.4329 26.4384C25.8055 27.0691 24.9535 27.4268 24.0629 27.4335ZM24.0629 21.9649C23.6454 21.9599 23.2356 22.0789 22.886 22.3067C22.5364 22.5345 22.2626 22.8609 22.0993 23.2444C21.9361 23.6279 21.8909 24.0512 21.9693 24.4605C22.0476 24.8697 22.2463 25.2467 22.5398 25.5431C22.8332 25.8395 23.2084 26.0422 23.6176 26.1253C24.0267 26.2085 24.4509 26.1683 24.8372 26.01C25.2235 25.8518 25.5543 25.5826 25.7868 25.2365C26.0193 24.8904 26.1436 24.4831 26.1436 24.0664C26.1436 23.5135 25.9254 22.9828 25.5359 22.5895C25.1465 22.1961 24.617 21.9716 24.0629 21.9649Z"
      fill="#F24892"
    />
    <path
      d="M24.0646 32.5913C22.3735 32.5913 20.7203 32.0908 19.3142 31.1533C17.9081 30.2158 16.8122 28.8835 16.165 27.3245C15.5179 25.7655 15.3489 24.0499 15.6788 22.3949C16.0087 20.7398 16.8226 19.2197 18.0184 18.0265C19.2141 16.8332 20.7379 16.0205 22.3965 15.6913C24.0551 15.3621 25.7741 15.531 27.3364 16.1768C28.8988 16.8226 30.2343 17.9161 31.1738 19.3192C32.1133 20.7223 32.6152 22.3719 32.6152 24.0594C32.6085 26.3201 31.7055 28.4863 30.1034 30.0849C28.5014 31.6835 26.3302 32.5846 24.0646 32.5913ZM24.0646 16.7934C22.6244 16.7934 21.2166 17.2194 20.0191 18.0178C18.8216 18.8162 17.8881 19.9509 17.337 21.2786C16.7859 22.6063 16.6416 24.0675 16.9226 25.477C17.2036 26.8865 17.8975 28.1811 18.9159 29.1973C19.9343 30.2135 21.2317 30.9054 22.6443 31.1857C24.0568 31.4661 25.5209 31.3222 26.8514 30.7722C28.182 30.2223 29.3188 29.2911 30.1189 28.0962C30.919 26.9013 31.3466 25.4965 31.3466 24.0594C31.3466 22.1323 30.5789 20.2841 29.2133 18.9215C27.8477 17.5588 25.9958 16.7934 24.0646 16.7934Z"
      fill="#FCFCFD"
    />
    <path
      d="M24.0602 43.7879C20.1511 43.7879 16.3298 42.6315 13.0796 40.4644C9.82928 38.2973 7.29597 35.217 5.80003 31.6133C4.30409 28.0095 3.91253 24.0441 4.67515 20.2184C5.43777 16.3926 7.32049 12.8784 10.0846 10.1202C12.8488 7.36201 16.3703 5.48366 20.2042 4.72267C24.0382 3.96169 28.0125 4.35241 31.624 5.84514C35.2355 7.33786 38.3218 9.86572 40.4936 13.109C42.6654 16.3523 43.8249 20.1653 43.8249 24.066C43.8115 29.2925 41.7245 34.301 38.0208 37.9967C34.3172 41.6924 29.298 43.7746 24.0602 43.7879ZM24.0602 5.60964C20.402 5.60964 16.8261 6.69221 13.7845 8.72021C10.7428 10.7482 8.37191 13.6306 6.97199 17.003C5.57207 20.3755 5.20591 24.0863 5.91958 27.6664C6.63325 31.2466 8.39483 34.5354 10.9816 37.1165C13.5683 39.6977 16.8641 41.4555 20.452 42.1676C24.0399 42.8797 27.7587 42.5144 31.1384 41.1175C34.5181 39.7205 37.407 37.3547 39.4393 34.3196C41.4717 31.2845 42.5563 27.7163 42.5563 24.066C42.5496 19.1732 40.5986 14.4827 37.1313 11.0229C33.6641 7.56316 28.9636 5.61634 24.0602 5.60964Z"
      fill="#FCFCFD"
    />
    <path
      d="M24.0604 47.0009C23.8922 47.0009 23.7309 46.9342 23.612 46.8155C23.493 46.6968 23.4261 46.5359 23.4261 46.368V29.4309C23.4074 29.339 23.4091 29.2441 23.4317 29.1531C23.4543 29.062 23.4974 28.9769 23.5568 28.9043C23.6163 28.8317 23.6912 28.7733 23.7761 28.7331C23.8611 28.6929 23.954 28.6719 24.048 28.6719C24.142 28.6719 24.2344 28.6929 24.3194 28.7331C24.4043 28.7733 24.4791 28.8317 24.5386 28.9043C24.5981 28.9769 24.6412 29.062 24.6638 29.1531C24.6863 29.2441 24.688 29.339 24.6693 29.4309V46.368C24.6695 46.5316 24.6063 46.6888 24.4928 46.8068C24.3793 46.9248 24.2242 46.9944 24.0604 47.0009Z"
      fill="#F24892"
    />
    <path
      d="M24.0604 19.4301C23.8922 19.4301 23.7309 19.3637 23.612 19.245C23.493 19.1263 23.4261 18.965 23.4261 18.7972V1.75871C23.4074 1.66679 23.4091 1.57194 23.4317 1.48088C23.4543 1.38982 23.4974 1.30503 23.5568 1.2324C23.6163 1.15978 23.6912 1.10107 23.7761 1.06088C23.8611 1.02069 23.954 1 24.048 1C24.142 1 24.2344 1.02069 24.3194 1.06088C24.4043 1.10107 24.4791 1.15978 24.5386 1.2324C24.5981 1.30503 24.6412 1.38982 24.6638 1.48088C24.6863 1.57194 24.688 1.66679 24.6693 1.75871V18.9239C24.6405 19.0647 24.5644 19.1916 24.4538 19.2836C24.3431 19.3756 24.2045 19.4272 24.0604 19.4301Z"
      fill="#F24892"
    />
    <path
      d="M18.6339 24.6956H1.63429C1.46607 24.6956 1.30478 24.6288 1.18583 24.5101C1.06688 24.3914 1 24.2305 1 24.0626C1 23.8948 1.06688 23.7338 1.18583 23.6151C1.30478 23.4964 1.46607 23.4297 1.63429 23.4297H18.6339C18.8021 23.4297 18.9634 23.4964 19.0824 23.6151C19.2013 23.7338 19.2682 23.8948 19.2682 24.0626C19.2682 24.2305 19.2013 24.3914 19.0824 24.5101C18.9634 24.6288 18.8021 24.6956 18.6339 24.6956Z"
      fill="#F24892"
    />
    <path
      d="M46.3657 24.6956H29.2905C29.1223 24.6956 28.961 24.6288 28.8421 24.5101C28.7231 24.3914 28.6562 24.2305 28.6562 24.0626C28.6562 23.8948 28.7231 23.7338 28.8421 23.6151C28.961 23.4964 29.1223 23.4297 29.2905 23.4297H46.3657C46.5339 23.4297 46.6952 23.4964 46.8142 23.6151C46.9331 23.7338 47 23.8948 47 24.0626C47 24.2305 46.9331 24.3914 46.8142 24.5101C46.6952 24.6288 46.5339 24.6956 46.3657 24.6956Z"
      fill="#F24892"
    />
  </svg>
);

export default ContentAccuracy;
